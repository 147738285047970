import { api } from '../../../../app/baseQuery';
import { HTTPResponse } from '../../../../common/types/commonTypes';

import {
  AirportDataType,
  IAirport,
  IAirportPagination,
  IAirportReqBody,
  ICountryAirport,
} from '../types/AirportTypes';

const airport = 'airport';
export const airportEndpoints = api.injectEndpoints({
  endpoints: (build) => ({
    //get all airports

    getAllAirport: build.query<HTTPResponse<IAirport[]>, void>({
      query: (arg) => ({
        url: `/configuration/airports/view-all`,
      }),
      providesTags: () => [{ type: 'Airports', id: airport }],
    }),

    //get all with pagination
    getAllAirportWithPagination: build.query<
      HTTPResponse<IAirportPagination>,
      { current: number; pageSize: number }
    >({
      query: (arg) => ({
        url: `/configuration/airports?page=${arg.current}&size=${arg.pageSize}`,
      }),
      providesTags: () => [{ type: 'Airports', id: airport }],
    }),

    //get all countries

    getAllCountries: build.query<HTTPResponse<ICountryAirport[]>, void>({
      query: () => ({ url: `/configuration/airports/view-all-countries` }),
      providesTags: () => [{ type: 'Airports', id: 'countries' }],
    }),
    getAllCountriesNew: build.query<HTTPResponse<ICountryAirport[]>, string>({
      query: (search) => ({
        url: `/configuration/airports/view-all-countries${search ? `?search=${search}` : ''}`,
      }),
      providesTags: () => [{ type: 'Airports', id: 'countries' }],
    }),
    //create airport

    createAirport: build.mutation<void, IAirportReqBody>({
      query: (body) => ({
        url: `/configuration/airports/create`,
        method: 'POST',
        body: body,
      }),

      invalidatesTags: () => [{ type: 'Airports', id: airport }],
    }),

    //edit airport
    editAirport: build.mutation<void, AirportDataType>({
      query: (body) => ({
        url: `/configuration/airports/${body.airline_id}`,
        method: 'PATCH',
        body: body,
      }),

      invalidatesTags: () => [{ type: 'Airports', id: airport }],
    }),

    //Delete airport

    deleteAirport: build.mutation<void, { airline_id: number; deleted_by: number }>({
      query: ({ airline_id, deleted_by }) => ({
        url: `configuration/airports/delete/${airline_id}`,
        method: 'DELETE',
        body: { deleted_by },
      }),

      invalidatesTags: () => [{ type: 'Airports', id: airport }],
    }),
  }),
});

export const {
  useGetAllAirportQuery,
  useGetAllCountriesQuery,
  useCreateAirportMutation,
  useEditAirportMutation,
  useDeleteAirportMutation,
  useGetAllAirportWithPaginationQuery,
  useGetAllCountriesNewQuery,
} = airportEndpoints;
