// import { Col, DatePicker, Form, Input, Row, Typography } from 'antd';
// import Table from 'antd/es/table';
// import dayjs from 'dayjs';
// import { useEffect, useRef, useState } from 'react';
// import { useLocation } from 'react-router';
// import { useReactToPrint } from 'react-to-print';
// import { Fixed2, rangePresets } from '../../../../common/utils/common.utils';
// import { FormButton } from '../../../../components/common/FormItem/FormItems';
// import { SelectAirline } from '../../../../components/common/FormItem/SelectCustomFeilds';
// import CommonViewReport from '../../../../components/common/Invoice/ViewInvoiceInfo/CommonViewReport';
// import BreadCrumb from '../../../../components/common/breadCrumb/BreadCrumb';
// import LoadingIndicator from '../../../../components/common/spinner/LoadingIndicator';
// import { useLazyGetAirTicketSummary2Query } from '../../AirlineWiseSalesReport/endpoints/AirlineWiseSalesReportEndpoints';
// import { AirTicketSummaryCol } from '../Utils/AirTicketSummryColumn';

// type Props = {};

// function AirTicketSummary({}: Props) {
//   const location = useLocation();
//   const to_date = dayjs().format('YYYY-MM-DD');
//   const [queryData, setQueryData] = useState<{
//     isTrash: '0' | '1';
//     current: number;
//     pageSize: number;
//   }>({ current: 0, isTrash: '0', pageSize: 50 });
//   const [dataRange, setDateRange] = useState(`from_date=${to_date}&to_date=${to_date}`);
//   const searchParams = new URLSearchParams(location.search);
//   const [form] = Form.useForm();
//   const toDate = dayjs().format('YYYY-MM-DD');
//   const [date, setDate] = useState({ toDate: toDate, fromDate: toDate });
//   const [client, setClient] = useState('');

//   const tableRef = useRef(null);

//   // Handle Pagination
//   const [pagination, setPagination] = useState({
//     current: 1,
//     pageSize: 50,
//   });

//   const handlePaginationChange = (current: number, pageSize: number) => {
//     setPagination((prevPagination) => ({
//       ...prevPagination,
//       current,
//       pageSize,
//     }));
//   };

//   const [fetchDATA, { data, isFetching, isLoading }] = useLazyGetAirTicketSummary2Query();

//   useEffect(() => {
//     if (searchParams && location.search) {
//       const formDate = searchParams.get('from_date');
//       const toDate = searchParams.get('to_date');
//       if (toDate) form.setFieldValue('date_range', [dayjs(formDate), dayjs(toDate)]);
//       form.setFieldValue('client', 'All');
//       form.submit();
//     } else {
//       form.setFieldValue('date_range', [dayjs(), dayjs()]);
//       form.setFieldValue('client', 'All');
//     }
//   }, []);

//   // const [getExcel] = useLazyGetAirTicketExcelQuery();

//   // const downloadExcel = () => {
//   //   getExcel({ formDate: date.fromDate, todDate: date.toDate });
//   // };

//   const onFinish = async (values: any) => {
//     const setFromDate = dayjs(values.date_range?.[0]).format('YYYY-MM-DD');
//     const setToDate = dayjs(values.date_range?.[1]).format('YYYY-MM-DD');
//     const queryParams = new URLSearchParams();
//     queryParams.append('from_date', setFromDate);
//     queryParams.append('to_date', setToDate);

//     if (values.airline_id) queryParams.append('airline_id', values.airline_id.toString());
//     if (values.segment) queryParams.append('segment', values.segment.trim());
//     if (values.search) queryParams.append('search', values.search.trim());
//     const query = `?${queryParams.toString()}&page=${queryData.current}&size=${queryData.pageSize}`;

//     setDateRange(`from_date=${setFromDate}&to_date=${setToDate}`);

//     await fetchDATA(query as any);
//   };

//   const downloadPdf = useReactToPrint({
//     content: () => tableRef.current,
//     documentTitle: `AirTicket Summary`,
//   });

//   const jsx_com = (
//     <Table
//       bordered
//       size="small"
//       scroll={{ x: 1100 }}
//       dataSource={data?.data}
//       columns={AirTicketSummaryCol({ pagination })}
//       loading={{ spinning: isFetching, indicator: <LoadingIndicator /> }}
//       summary={() => {
//         return (
//           <Table.Summary.Row style={{ background: '#031c291f', height: 40 }}>
//             <Table.Summary.Cell index={0} colSpan={5}>
//               SUMMARY
//             </Table.Summary.Cell>

//             <Table.Summary.Cell className="font-bold" index={10}></Table.Summary.Cell>
//             <Table.Summary.Cell className="font-bold" index={10}></Table.Summary.Cell>
//             <Table.Summary.Cell className="font-bold" index={10}></Table.Summary.Cell>

//             <Table.Summary.Cell className="font-bold" index={10}>
//               {' '}
//               {Fixed2(data?.summary?.total_airticket_discount_total).toLocaleString()}
//             </Table.Summary.Cell>
//             <Table.Summary.Cell className="font-bold" index={10}>
//               {Fixed2(data?.summary?.total_airticket_extra_fee).toLocaleString()}
//             </Table.Summary.Cell>
//             <Table.Summary.Cell className="font-bold" index={6}>
//               {' '}
//               {Fixed2(data?.summary?.total_airticket_client_price).toLocaleString()}
//             </Table.Summary.Cell>

//             <Table.Summary.Cell className="font-bold" index={8}>
//               {Fixed2(data?.summary?.total_airticket_purchase_price).toLocaleString()}
//             </Table.Summary.Cell>

//             <Table.Summary.Cell className="font-bold" index={9}>
//               <strong style={{ color: 'green' }}>
//                 {Fixed2(data?.summary?.total_airticket_profit).toLocaleString()}
//               </strong>
//             </Table.Summary.Cell>

//             <Table.Summary.Cell className="font-bold" index={10}></Table.Summary.Cell>
//           </Table.Summary.Row>
//         );
//       }}
//       pagination={
//         data?.count !== undefined && data?.count < 50
//           ? false
//           : {
//               ...pagination,
//               total: data?.count,
//               showSizeChanger: true,
//               pageSizeOptions: ['50', '100', '200', '500'],
//               onChange: handlePaginationChange,
//             }
//       }
//     />
//   );

//   const print_content = (
//     <div hidden>
//       <CommonViewReport
//         children={jsx_com}
//         printRef={tableRef}
//         title={{
//           info_title: '',
//           title: 'Air Ticket Summary',
//         }}
//       />
//     </div>
//   );

//   return (
//     <>
//       <BreadCrumb arrOfOption={['Reports', 'Air Ticket Summary']} />

//       <Form layout="vertical" form={form} onFinish={onFinish} initialValues={{ airline_id: 'all' }}>
//         <Row justify={'space-between'}>
//           <Col sm={24} md={24} lg={24}>
//             <div style={{ display: 'flex', gap: 10 }}>
//               <SelectAirline
//                 required
//                 name="airline_id"
//                 label="Select Airline"
//                 size={4}
//                 showAll
//                 allowClear={false}
//               />
//               <Col span={6} xs={24} sm={24} md={4} lg={4}>
//                 <Form.Item label="Segment" name="segment">
//                   <Input placeholder="Search by Segment" type="number" allowClear />
//                 </Form.Item>
//               </Col>
//               <Col span={6} xs={24} sm={24} md={4} lg={4}>
//                 <Form.Item label="Search" name="search">
//                   <Input placeholder="Search by ticket no./PNR" allowClear />
//                 </Form.Item>
//               </Col>
//               <Form.Item
//                 name="date_range"
//                 label="Date Range"
//                 rules={[{ required: true, message: ` Date Range is required!` }]}
//               >
//                 <DatePicker.RangePicker
//                   presets={rangePresets}
//                   format={'DD-MM-YYYY'}
//                   allowClear={false}
//                   style={{ width: '100%' }}
//                 />
//               </Form.Item>

//               <Col lg={2}>
//                 <FormButton label="Search" icon textAlign="left" loading={isLoading} />
//               </Col>
//             </div>
//           </Col>

//           <Col style={{ display: 'flex', gap: 10 }}>
//             {/* <Button type="primary" onClick={downloadPdf}>
//               <PrinterOutlined />
//               Print
//             </Button> */}
//             {/* <Button onClick={downloadExcel} type='primary'>
//               <ExceptionOutlined />
//               Excel
//             </Button> */}
//           </Col>
//         </Row>
//       </Form>

//       <Typography.Text style={{ margin: '5px 0px' }}>
//         You have total: {data?.count} rows
//       </Typography.Text>

//       {jsx_com}

//       {print_content}
//     </>
//   );
// }

// export default AirTicketSummary;

import { Col, DatePicker, Form, Input, Row, Typography } from 'antd';
import Table from 'antd/es/table';
import dayjs from 'dayjs';
import { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router';
import { useReactToPrint } from 'react-to-print';
import { Fixed2, rangePresets } from '../../../../common/utils/common.utils';
import { FormButton } from '../../../../components/common/FormItem/FormItems';
import { SelectAirline } from '../../../../components/common/FormItem/SelectCustomFeilds';
import CommonViewReport from '../../../../components/common/Invoice/ViewInvoiceInfo/CommonViewReport';
import BreadCrumb from '../../../../components/common/breadCrumb/BreadCrumb';
import LoadingIndicator from '../../../../components/common/spinner/LoadingIndicator';
import { useLazyGetAirTicketSummary2Query } from '../../AirlineWiseSalesReport/endpoints/AirlineWiseSalesReportEndpoints';
import { AirTicketSummaryCol } from '../Utils/AirTicketSummryColumn';

type Props = {};

function AirTicketSummary({}: Props) {
  const location = useLocation();
  const to_date = dayjs().format('YYYY-MM-DD');
  const [queryData, setQueryData] = useState<{
    isTrash: '0' | '1';
    current: number;
    pageSize: number;
  }>(() => {
    // Initialize from sessionStorage or default values
    const saved = sessionStorage.getItem('airTicketSummaryQueryData');
    return saved ? JSON.parse(saved) : { current: 0, isTrash: '0', pageSize: 50 };
  });

  const [dataRange, setDateRange] = useState(() => {
    const saved = sessionStorage.getItem('airTicketSummaryDateRange');
    return saved || `from_date=${to_date}&to_date=${to_date}`;
  });

  const [pagination, setPagination] = useState(() => {
    const saved = sessionStorage.getItem('airTicketSummaryPagination');
    return saved
      ? JSON.parse(saved)
      : {
          current: 1,
          pageSize: 20,
        };
  });

  const [form] = Form.useForm();
  const tableRef = useRef(null);
  const [fetchDATA, { data, isFetching, isLoading }] = useLazyGetAirTicketSummary2Query();

  // Save state to sessionStorage whenever it changes
  useEffect(() => {
    sessionStorage.setItem('airTicketSummaryQueryData', JSON.stringify(queryData));
    sessionStorage.setItem('airTicketSummaryDateRange', dataRange);
    sessionStorage.setItem('airTicketSummaryPagination', JSON.stringify(pagination));
  }, [queryData, dataRange, pagination]);
  // Load previous search parameters
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const savedFormData = sessionStorage.getItem('airTicketSummaryFormData');

    if (savedFormData) {
      const parsedFormData = JSON.parse(savedFormData);
      form.setFieldsValue({
        ...parsedFormData,
        date_range: parsedFormData.date_range.map((date: string) => dayjs(date)),
      });
      // Trigger search with saved parameters
      form.submit();
    } else if (searchParams && location.search) {
      const formDate = searchParams.get('from_date');
      const toDate = searchParams.get('to_date');
      const airlineId = searchParams.get('airline_id');
      const segment = searchParams.get('segment');
      const search = searchParams.get('search');

      form.setFieldsValue({
        date_range: [dayjs(formDate), dayjs(toDate)],
        airline_id: airlineId || 'all',
        segment: segment || undefined,
        search: search || undefined,
      });
      form.submit();
    } else {
      form.setFieldsValue({
        date_range: [dayjs(), dayjs()],
        airline_id: 'all',
      });
    }
  }, []);

  const handlePaginationChange = (current: number, pageSize: number) => {
    setPagination((prevPagination: any) => ({
      ...prevPagination,
      current,
      pageSize,
    }));
  };

  const onFinish = async (values: any) => {
    const setFromDate = dayjs(values.date_range?.[0]).format('YYYY-MM-DD');
    const setToDate = dayjs(values.date_range?.[1]).format('YYYY-MM-DD');
    const queryParams = new URLSearchParams();
    queryParams.append('from_date', setFromDate);
    queryParams.append('to_date', setToDate);

    if (values.airline_id) queryParams.append('airline_id', values.airline_id.toString());
    if (values.segment) queryParams.append('segment', values.segment.trim());
    if (values.search) queryParams.append('search', values.search.trim());
    const query = `?${queryParams.toString()}&page=${queryData.current}&size=${queryData.pageSize}`;

    setDateRange(`from_date=${setFromDate}&to_date=${setToDate}`);

    // Save form data to sessionStorage
    sessionStorage.setItem(
      'airTicketSummaryFormData',
      JSON.stringify({
        ...values,
        date_range: [setFromDate, setToDate],
      })
    );

    await fetchDATA(query as any);
  };

  const downloadPdf = useReactToPrint({
    content: () => tableRef.current,
    documentTitle: `AirTicket Summary`,
  });

  const jsx_com = (
    <Table
      bordered
      size="small"
      scroll={{ x: 1100 }}
      dataSource={data?.data}
      columns={AirTicketSummaryCol({ pagination })}
      loading={{ spinning: isFetching, indicator: <LoadingIndicator /> }}
      summary={() => {
        return (
          <Table.Summary.Row style={{ background: '#031c291f', height: 40 }}>
            <Table.Summary.Cell index={0} colSpan={5}>
              SUMMARY
            </Table.Summary.Cell>
            <Table.Summary.Cell className="font-bold" index={10}></Table.Summary.Cell>
            <Table.Summary.Cell className="font-bold" index={10}></Table.Summary.Cell>
            <Table.Summary.Cell className="font-bold" index={10}></Table.Summary.Cell>
            <Table.Summary.Cell className="font-bold" index={10}>
              {' '}
              {Fixed2(data?.summary?.total_airticket_discount_total).toLocaleString()}
            </Table.Summary.Cell>
            <Table.Summary.Cell className="font-bold" index={10}>
              {Fixed2(data?.summary?.total_airticket_extra_fee).toLocaleString()}
            </Table.Summary.Cell>
            <Table.Summary.Cell className="font-bold" index={6}>
              {' '}
              {Fixed2(data?.summary?.total_airticket_client_price).toLocaleString()}
            </Table.Summary.Cell>
            <Table.Summary.Cell className="font-bold" index={8}>
              {Fixed2(data?.summary?.total_airticket_purchase_price).toLocaleString()}
            </Table.Summary.Cell>
            <Table.Summary.Cell className="font-bold" index={9}>
              <strong style={{ color: 'green' }}>
                {Fixed2(data?.summary?.total_airticket_profit).toLocaleString()}
              </strong>
            </Table.Summary.Cell>{' '}
          </Table.Summary.Row>
        );
      }}
      pagination={
        data?.count !== undefined && data?.count < 50
          ? false
          : {
              ...pagination,
              total: data?.count,
              showSizeChanger: true,
              pageSizeOptions: ['50', '100', '200', '500'],
              onChange: handlePaginationChange,
            }
      }
    />
  );

  const print_content = (
    <div hidden>
      <CommonViewReport
        children={jsx_com}
        printRef={tableRef}
        title={{
          info_title: '',
          title: 'Air Ticket Summary',
        }}
      />
    </div>
  );

  return (
    <>
      <BreadCrumb arrOfOption={['Reports', 'Air Ticket Summary']} />

      <Form layout="vertical" form={form} onFinish={onFinish} initialValues={{ airline_id: 'all' }}>
        <Row justify={'space-between'}>
          <Col sm={24} md={24} lg={24}>
            <div style={{ display: 'flex', gap: 10 }}>
              <SelectAirline
                required
                name="airline_id"
                label="Select Airline"
                size={4}
                showAll
                allowClear={false}
              />
              <Col span={6} xs={24} sm={24} md={4} lg={4}>
                <Form.Item label="Segment" name="segment">
                  <Input placeholder="Search by Segment" type="number" allowClear />
                </Form.Item>
              </Col>
              <Col span={6} xs={24} sm={24} md={4} lg={4}>
                <Form.Item label="Search" name="search">
                  <Input placeholder="Search by ticket no./PNR" allowClear />
                </Form.Item>
              </Col>
              <Form.Item
                name="date_range"
                label="Date Range"
                rules={[{ required: true, message: ` Date Range is required!` }]}
              >
                <DatePicker.RangePicker
                  presets={rangePresets}
                  format={'DD-MM-YYYY'}
                  allowClear={false}
                  style={{ width: '100%' }}
                />
              </Form.Item>

              <Col lg={2}>
                <FormButton label="Search" icon textAlign="left" loading={isLoading} />
              </Col>
            </div>
          </Col>

          <Col style={{ display: 'flex', gap: 10 }}>
            {/* <Button type="primary" onClick={downloadPdf}>
              <PrinterOutlined />
              Print
            </Button> */}
            {/* <Button onClick={downloadExcel} type='primary'>
              <ExceptionOutlined />
              Excel
            </Button> */}
          </Col>
        </Row>
      </Form>

      <Typography.Text style={{ margin: '5px 0px' }}>
        You have total: {data?.count} rows
      </Typography.Text>

      {jsx_com}

      {print_content}
    </>
  );
}

export default AirTicketSummary;
