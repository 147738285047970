import { Tooltip } from 'antd';
import { ColumnsType } from 'antd/es/table';
import dayjs from 'dayjs';
import { Link } from 'react-router-dom';
import { Fixed2 } from '../../../../common/utils/common.utils';
import { IAirTicketSummary } from '../Interfaces/AirTicketReport.interfaces';

// export const AirTicketSummaryCol: ColumnsType<IAirTicketSummary> = [

export const AirTicketSummaryCol = ({
  pagination,
}: {
  pagination: {
    current: number;
    pageSize: number;
  };
}): ColumnsType<IAirTicketSummary> => {
  return [
    {
      title: 'SL.',
      key: 'invoice_id',
      render: (_, data, index) => (
        <>{((pagination.current || 1) - 1) * (pagination.pageSize || 20) + 1 + index}</>
      ),
    },
    {
      title: (
        <>
          <p className="table-column-title">Sales Date</p>
          <p className="table-column-title">Invoice No</p>
        </>
      ),
      render: (record) => (
        <>
          <p>{dayjs(record?.create_date).format('DD MMM YYYY')}</p>
          <Link target="_blank" to={`/airticket/details/${record.invoice_id}`}>
            {record?.invoice_no}
          </Link>{' '}
        </>
      ),
    },
    {
      title: (
        <>
          <p className="table-column-title">Client Name</p>
          <p className="table-column-title">Mobile No</p>
        </>
      ),
      render: (record) => (
        <>
          <Link
            target="_blank"
            to={
              record.airticket_client_id
                ? `/clients/details/client-${record.airticket_client_id}`
                : `/combineClients/details/${record.airticket_combined_id}`
            }
            state={record.airticket_client_id ? `/clients` : `/combineds`}
          >
            {record.client_name}
          </Link>{' '}
          <p>{record?.client_mobile}</p>
        </>
      ),
    },

    {
      title: (
        <>
          <p className="table-column-title">Ticket No</p>
          <p className="table-column-title">Airline</p>
        </>
      ),
      render: (record) => (
        <>
          <p>
            <Tooltip style={{ cursor: 'pointer' }} placement="topLeft" title={record?.airline_name}>
              {record?.airline_code}
            </Tooltip>
            {record?.airticket_ticket_no && ' - '}
            {record?.airticket_ticket_no}
          </p>
        </>
      ),
      width: '150px',
    },

    {
      title: (
        <>
          <p className="table-column-title">Journey Date</p>
          <p className="table-column-title">Return Date</p>
        </>
      ),
      render: (record) => (
        <>
          <p>
            {record.airticket_journey_date
              ? dayjs(record.airticket_journey_date).format('DD MMM YYYY')
              : ''}
          </p>
          <p>
            {record.airticket_return_date
              ? dayjs(record.airticket_return_date).format('DD MMM YYYY')
              : ''}
          </p>{' '}
        </>
      ),
      width: '100px',
    },
    {
      title: 'PNR',
      dataIndex: 'airticket_pnr',
      key: 'airticket_pnr',
    },
    {
      title: (
        <>
          <p className="table-column-title">Routes</p>
        </>
      ),
      render: (record) => (
        <>
          <p>{record?.airticket_routes}</p>
        </>
      ),
      width: '150px',
    },

    {
      title: 'Segment',
      render: (record) => (
        <span>
          {record?.airticket_profit < 0 ? Math.abs(record?.airticket_profit) : ' '}
          {record?.airticket_segment && ' - '}
          {record?.airticket_segment && record?.airticket_segment}
        </span>
      ),
    },
    {
      title: 'Discount',
      dataIndex: 'airticket_discount_total',
      key: 'airticket_discount_total',
      align: 'right',
      render: (value) => Fixed2(value).toLocaleString(),
    },
    {
      title: 'Extra Fee',
      dataIndex: 'airticket_extra_fee',
      key: 'airticket_extra_fee	',
      align: 'right',
      render: (value) => Fixed2(value).toLocaleString(),
    },
    {
      title: 'Client Price',
      dataIndex: 'airticket_client_price',
      key: 'airticket_client_price',
      align: 'right',
      render: (value) => Fixed2(value).toLocaleString(),
    },
    {
      title: 'Purchase Price',
      dataIndex: 'airticket_purchase_price',
      key: 'airticket_purchase_price',
      align: 'right',
      render: (value) => Fixed2(value).toLocaleString(),
    },
    {
      title: 'Profit',
      dataIndex: 'airticket_profit',
      key: 'airticket_profit',
      align: 'right',
      render: (value) => (
        <span className={value > 0 ? 'font-bold font-success' : 'font-bold font-warning'}>
          {Fixed2(value).toLocaleString()}
        </span>
      ),
    },
    // {
    //   title: 'Segment Charge',
    //   dataIndex: 'airticket_profit',
    //   key: 'airticket_profit',
    //   align: 'right',
    //   width: '80px',
    //   render: (value) => (
    //     <span className={value > 0 ? 'font-bold font-success' : 'font-bold font-warning'}>
    //       {value < 0 ? Fixed2(value) : ''}
    //     </span>
    //   ),
    // },
  ];
};
